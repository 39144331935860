import * as React from "react"
import Cats from "../images/cats.jpg"
// styles
const pageStyles = {
  color: "#232129",
  padding: 20,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  textAlign: "center",
}
const imageStyles = {
  width:"100%",
  maxWidth: "100%",
  height: "auto",
}

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <img
        style={imageStyles}
        alt="cats"
        src={ Cats }
      />
    </main>
  )
}

export default IndexPage
